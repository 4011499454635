<template>
  <div class="card card-custom" v-if="loaded && isCan">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder">
          {{ $t("attendance.Attendance_Summary_Report") }}
        </h3>
      </div>
    </div>

    <div class="card-body pt-0">
      <div class="row">
        <div class="col-3">
          <label class="d-flex">{{ $t("attendance.Reporting_Period") }}:</label>
          <date-range-picker
            ref="picker"
            :show-dropdowns="true"
            :linked-calendars="true"
            :close-on-esc="true"
            :ranges="false"
            v-model="dateRange"
            @update="updateDataRange"
            control-container-class=""
            :locale-data="$t('data-range-piker')"
          >
            <div
              slot="input"
              slot-scope="data"
              style="min-width: 300px"
              class="input-group"
            >
              <div
                v-if="data.startDate"
                type="text"
                class="form-control"
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              >
                {{ data.startDate | formatDate }} -
                {{ data.endDate | formatDate }}
              </div>

              <div
                v-else
                type="text"
                class="form-control"
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              >
                {{ $t("event.Select_Period") }}
              </div>

              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2"
                  ><i class="flaticon-event-calendar-symbol"></i
                ></span>
              </div>
            </div>

            <div slot="footer" slot-scope="data" class="drp-buttons">
              <span data-v-4f8eb193="" class="drp-selected">
                {{ data.rangeText }}
              </span>

              <button
                @click="clickDataRangeCancel(data)"
                type="button"
                class="cancelBtn btn btn-sm btn-secondary"
              >
                {{ data.locale.cancelLabel }}
              </button>

              <button
                @click="data.clickApply"
                v-if="!data.in_selection"
                type="button"
                class="applyBtn btn btn-sm btn-success"
              >
                {{ data.locale.applyLabel }}
              </button>
            </div>
          </date-range-picker>
        </div>

        <div class="col-3">
          <label class="d-flex">{{ $t("attendance.Company") }}:</label>
          <select class="form-control w-75" v-model="filter.company_id">
            <option :value="null" selected>{{ $t("common.all") }}</option>
            <option v-for="item in companies" :value="item.id" :key="item.id">
              {{ item.name }}
            </option>
          </select>
        </div>

        <div class="col-12">
          <core-datatable
            :loaded="true"
            v-bind:columns.sync="columns"
            :showFooter="true"
            :items="summary"
            v-bind:filter.sync="filter"
            :totalCount="summaryTotalCount"
          >
            <template #user_name="{ item }">
              <div class="d-flex align-items-center">
                <div>
                  <div
                    class="text-dark-75 text-truncate font-weight-bolder font-size-lg mb-0"
                    style="max-width: 250px"
                  >
                    <span class="text-capitalize">{{ item.user_name }}</span>
                  </div>
                </div>
              </div>
            </template>

            <template #time_start="{ item }">
              {{ item.averageWorkingTimeStart }}
            </template>

            <template #time_end="{ item }">
              {{ item.averageWorkingTimeEnd }}
            </template>

            <template #time_spent_in="{ item }">
              {{ item.averageWorkingTimeSpentIn }}
            </template>

            <template #time_spent_out="{ item }">
              {{ item.averageWorkingTimeSpentOut }}
            </template>
          </core-datatable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import moment from "moment";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: { DateRangePicker },
  computed: {
    ...mapGetters({
      summary: "attendance/summary",
      summaryTotalCount: "attendance/totalCount",
      companies: "attendance/companies",
      loaded: "attendance/loaded",
      integrations: 'main/integrations',
    }),
    isCan() {
      if (this.integrations.indexOf('integration_visitors') === -1) {
        this.$router.push('/');
      }
      return this.integrations.indexOf('integration_visitors') > -1
    }
  },
  data() {
    return {
      dateRange: {},
      columns: [
        {
          text: "attendance.Employee",
          slot: "user_name",
          key: "user_name",
          class: "w-25",
        },
        {
          text: "attendance.Average_Working_Time_Start",
          slot: "time_start",
          key: "averageWorkingTimeStart",
        },
        {
          text: "attendance.Average_Working_Time_Finish",
          slot: "time_end",
          key: "averageWorkingTimeEnd",
        },
        {
          text: "attendance.Average_Working_Time_In_A_Building",
          slot: "time_spent_in",
          key: "averageWorkingTimeSpentIn",
        },
        {
          text: "attendance.Average_Working_Time_Outside_The_Building",
          slot: "time_spent_out",
          key: "averageWorkingTimeSpentOut",
        },
      ],
      summaryy: [
        {
          user_name: "Иванов Иван Иванович",
          user_id: "0",
          averageWorkingTimeStart: 1619087370,
          averageWorkingTimeEnd: 1619094570,
          averageWorkingTimeSpentIn: "9 час. 2 мин",
          averageWorkingTimeSpentOut: "1 час. 2 мин",
        },
        {
          user_name: "Петров Петр Петрович",
          user_id: "1",
          averageWorkingTimeStart: 1619087370,
          averageWorkingTimeEnd: 1619094570,
          averageWorkingTimeSpentIn: "9 час. 2 мин",
          averageWorkingTimeSpentOut: "1 час. 2 мин",
        },
      ],
      filter: {
        // user_name: '',
        // sort: [],
        company_id: null,
        from: "",
        to: "",
        page: 1,
        "per-page": 10,
      },
    };
  },
  created() {
    this.dateRange.startDate = moment().subtract(7, "d").format();
    this.dateRange.endDate = moment().format();
    this.filter.from = moment().subtract(7, "d").format("DD.MM.YYYY");
    this.filter.to = moment().format("DD.MM.YYYY");
  },
  mounted() {
    this.CompaniesInit();
    this.fetch();
  },
  watch: {
    filter: {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("attendance", ["CompaniesInit"]),
    fetch() {
      this.$store.dispatch("attendance/SummaryInit", this.filter);
    },
    moment: function () {
      return moment();
    },
    clickDataRangeCancel(data) {
      this.dateRange = {};
      data.rangeText = "";
      this.filter.from = "";
      this.filter.to = "";
      data.clickCancel();
    },
    updateDataRange(params) {
      this.filter.from = moment(params.startDate).format("DD.MM.YYYY");
      this.filter.to = moment(params.endDate).format("DD.MM.YYYY");
    },
  },
};
</script>

<style>
</style>